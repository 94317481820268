import React from 'react';

const Description = () => (
  <div>
    <h2>Description</h2>
    <p>This is a description of the product.</p>
  </div>
);

export default Description;
