import React, { useState } from 'react';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';
import Description from './components/Description';
import Video from './components/Video';
import './App.css';

function App() {
  const [paid, setPaid] = useState(false);

  const handleApprove = (orderID) => {
    // Ideally, verify the transaction with your server here.
    console.log(`Transaction completed with order ID: ${orderID}`);
    setPaid(true);
  };

  return (
    <div className="App">
      <h1>Landing Page</h1>
      <Description />
      <Video />
      {!paid ? (
        <PayPalScriptProvider options={{ "client-id": "AWxpScjmZTdv1O6hSddsExms_Fkh9KdBAk1ieiiYCjuk0V0ZSErOB8vvlvYQoc9R2imIiKlBfj51UZVC" }}>
          <PayPalButtons
            createOrder={(data, actions) => {
              return actions.order.create({
                purchase_units: [
                  {
                    amount: {
                      value: "o", // Set the amount for the transaction
                    },
                  },
                ],
              });
            }}
            onApprove={(data, actions) => {
              return actions.order.capture().then((details) => {
                handleApprove(details.id);
              });
            }}
          />
        </PayPalScriptProvider>
      ) : (
        <a href="/public/cd.zip" download>
          Download File
        </a>
      )}
    </div>
  );
}

export default App;
